import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import RadioButton from '@emcasa/ui-dom/components/RadioButton';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radiobutton"
    }}>{`RadioButton`}</h1>
    <p>{`Radio Button component. Notice these RadioButtons are not part of a RadioGroup, so they'll ignore selection rules.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`Basic radio buttons`}</p>
    <Playground __position={0} __code={'<RadioButton label=\"Uncontrolled RadioButton\" value=\"a\" />\n<RadioButton label=\"Controlled RadioButton\" value=\"b\" checked />\n<RadioButton label=\"Disabled RadioButton\" value=\"d\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      RadioButton
    }} mdxType="Playground">
  <RadioButton label="Uncontrolled RadioButton" value="a" mdxType="RadioButton" />
  <RadioButton label="Controlled RadioButton" value="b" checked mdxType="RadioButton" />
  <RadioButton label="Disabled RadioButton" value="d" disabled mdxType="RadioButton" />
    </Playground>
    <h1 {...{
      "id": "radiobuttongroup"
    }}>{`RadioButton.Group`}</h1>
    <p>{`Grouped radio buttons`}</p>
    <Playground __position={1} __code={'<RadioButton.Group onChange={value => console.log(\'selected\', value)}>\n  <RadioButton label=\"Option A\" value=\"a\" />\n  <RadioButton label=\"Option B\" value=\"b\" />\n  <RadioButton label=\"Option C\" value=\"c\" />\n</RadioButton.Group>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      RadioButton
    }} mdxType="Playground">
  <RadioButton.Group onChange={value => console.log('selected', value)}>
    <RadioButton label="Option A" value="a" mdxType="RadioButton" />
    <RadioButton label="Option B" value="b" mdxType="RadioButton" />
    <RadioButton label="Option C" value="c" mdxType="RadioButton" />
  </RadioButton.Group>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      